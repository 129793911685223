<template>
  <div class="grid grid-cols-2 gap-rem-16 overflow-hidden h-full">
    <div :class="['col-span-1']">
      <LayoutCard title="报警趋势">
        <template #action>
          <range-picker
            @ok="v => handleSearchTime(v, '1')"
            v-if="alarmTrandSelectValue === 'CUSTOM_DAY'"
          />
          <a-select
            :options="alarmTypeSelectOptopn"
            v-model="alarmTrandSelectValue"
            @change="() => handleSelectChange('1')"
          ></a-select>
        </template>
        <div class="h-full overflow-hidden" style="padding: 0.16rem;">
          <LineChart
            chart-width="100%"
            chart-height="100%"
            :isSmooth="false"
            symbol="round"
            chartRight="2%"
            :chart-data="alarmTrandChartOption"
            :show-legend="true"
          />
        </div>
      </LayoutCard>
    </div>

    <div class="col-span-1 flex flex-col  gap-rem-16">
      <div>
        <LayoutCard title="临边防护平面图">
          <template #action>
            <ali-icon
              type="tc-icon-zhankai"
              @click="handleFullScreen"
            ></ali-icon>
          </template>
          <div :class="$style.planViewWrap">
            <PlanViewCanvas></PlanViewCanvas>
          </div>
        </LayoutCard>
      </div>
      <div class="grid grid-cols-2 gap-rem-16 flex-1">
        <div :class="['col-span-1']">
          <LayoutCard title="设备状态">
            <template #action>
              <span :class="$style.statusDetail" @click="showDetail">详情</span>
            </template>
            <PieChart
              :chartData="deviceStatusOption"
              :labelOption="deviceStatusLabelOption"
              left="10%"
              right="10%"
            />
          </LayoutCard>
        </div>
        <div :class="['col-span-1']">
          <LayoutCard title="报警类型分析">
            <template #action>
              <range-picker
                @ok="v => handleSearchTime(v, '2')"
                v-if="alarmTypeSelectValue === 'CUSTOM_DAY'"
              />
              <a-select
                :options="alarmTypeSelectOptopn"
                v-model="alarmTypeSelectValue"
                @change="() => handleSelectChange('2')"
              ></a-select>
            </template>
            <PieChart
              :chartData="alarmTypePieOption"
              :labelOption="alarmTypePieLebalOption"
              :legendWidth="100"
              left="10%"
              right="10%"
            />
          </LayoutCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { LayoutCard, LayoutSelect } from '../../../layout';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import LineChart from '@/views/screen/e-charts/line-chart';
import PieChart from '@/views/screen/e-charts/pie-chart.vue';
import { nowSize } from '@/utils/common';
import EdgeProtectionDataView from './components/data-view.vue';
import { createModal } from '@triascloud/x-components';
import DataListModal from './components/data-list.vue';
import {
  getEdgeDeviceStatus,
  getEdgeAlarmTypeStatics,
  getEdgeAlarmTypeTrend,
} from '@/services/screen/jiangsu/edge-protection';
import PlanViewCanvas from './components/canvas.vue';
import moment from 'moment';
import AliIcon from '@/components/ali-icon/index.vue';
import RangePicker from '@/views/screen/components/range-picker.vue';

@Component({
  components: {
    LayoutCard,
    LayoutSelect,
    ScrollTable,
    LineChart,
    PieChart,
    EdgeProtectionDataView,
    PlanViewCanvas,
    AliIcon,
    RangePicker,
  },
})
export default class EdgeProtectionIndex extends Vue {
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  colorArray = ['#6BBDFF'];
  // 趋势图
  alarmTrandData = {
    dataList: [],
    times: [],
  };
  alarmTrandSelectValue = 'SEVEN_DAY';
  get alarmTrandChartOption() {
    const { times, dataList } = this.alarmTrandData;
    return [
      {
        time: times.map(e => moment(e).valueOf()),
        data: dataList?.map(item => ({
          name: item.tag,
          data: item.values.map(value => ({
            value: +value === -1 ? 0 : value,
            unit: '次',
          })),
        })),
      },
    ];
  }
  async getAlarmTrand(startTime = undefined, endTime = undefined) {
    this.alarmTrandData = await getEdgeAlarmTypeTrend({
      days: this.alarmTrandSelectValue,
      projectId: this.projectId,
      startTime,
      endTime,
    });
  }

  // 警报类型分析
  alarmTypeSelectOptopn = [
    {
      label: '自定义',
      value: 'CUSTOM_DAY',
    },
    {
      label: this.$t('screen.currentDay'),
      value: 'CURRENT_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];
  alarmTypeSelectValue = 'SEVEN_DAY';
  alarmTypeData = [];
  get alarmTypePieLebalOption() {
    const total = this.alarmTypePieOption.reduce((a, b) => a + b.value, 0);
    return {
      show: true,
      position: 'center',
      formatter: `{a|预/报警次数}\n{b|${total}}`,
      rich: {
        a: {
          color: '#9AC0D9',
          fontSize: nowSize(12),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(18),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }
  get alarmTypePieOption() {
    return this.alarmTypeData.map(item => ({
      value: item.count ?? 0,
      name: item.typeName,
    }));
  }
  async getAlarmTypeStatics(startTime = undefined, endTime = undefined) {
    this.alarmTypeData = await getEdgeAlarmTypeStatics({
      days: this.alarmTypeSelectValue,
      projectId: this.projectId,
      startTime,
      endTime,
    });
  }

  handleSelectChange(type) {
    if (type === '1') {
      this.alarmTrandSelectValue !== 'CUSTOM_DAY' && this.getAlarmTrand();
    } else if (type === '2') {
      this.alarmTypeSelectValue !== 'CUSTOM_DAY' && this.getAlarmTypeStatics();
    }
  }

  // 时间筛选
  handleSearchTime(time, type) {
    const startTime = this.$moment(time[0]).valueOf();
    const endTime = this.$moment(time[1]).valueOf();
    if (type === '1') {
      this.getAlarmTrand(startTime, endTime);
    } else if (type === '2') {
      this.getAlarmTypeStatics(startTime, endTime);
    }
  }

  // 设备状态
  deviceStatusData = {};
  deviceStatusSelect = '';
  get deviceStatusOption() {
    const data = this.deviceStatusData;
    return [
      { value: data.normalCount ?? 0, name: '正常' },
      { value: data.lessMoveCount ?? 0, name: '缺失/位移' },
      { value: data.approachCount ?? 0, name: '人体接近' },
    ];
  }
  get deviceStatusLabelOption() {
    const total = this.deviceStatusOption.reduce((a, b) => a + b.value, 0);
    return {
      show: true,
      position: 'center',
      formatter: `{a|设备数}\n{b|${total}}`,
      rich: {
        a: {
          color: '#9AC0D9',
          fontSize: nowSize(12),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(18),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }
  async getDeviceStatus() {
    this.deviceStatusData = await getEdgeDeviceStatus(this.projectId);
  }
  showDetail() {
    createModal(() => <DataListModal projectId={this.projectId} />, {
      title: '临边设备数据',
      width: '80vw',
      className: 'screen-modal',
    });
  }

  handleFullScreen() {
    createModal(
      () => (
        <div
          class={'w-full flex justify-center items-center'}
          style={'aspect-ratio: 16/9;'}
        >
          <PlanViewCanvas isModal />
        </div>
      ),
      {
        width: '80vw',
        wrapClassName: 'konva-wrapper',
      },
    );
  }

  init() {
    this.getDeviceStatus();
    this.getAlarmTypeStatics();
    this.getAlarmTrand();
  }
  created() {
    this.init();
  }
}
</script>

<style lang="less" module>
.statusDetail {
  color: #37d7db;
  font-size: 0.14rem;
  cursor: pointer;
  user-select: none;
}
.planViewWrap {
  height: 45vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
