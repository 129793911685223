<template>
  <div>
    <div class="flex gap-8" style="margin-bottom: 0.1rem;">
      <a-select
        placeholder="全部设备"
        :options="deviceOption"
        v-model="params.deviceId"
        @change="selectChange"
        style="width: 2rem;"
        allowClear
      ></a-select>
      <a-select
        style="width: 2rem;"
        @change="selectChange"
        placeholder="全部布防状态"
        :options="onOffOption"
        v-model="params.defenseStatus"
        allowClear
      ></a-select>

      <a-select
        style="width: 2rem;"
        @change="selectChange"
        placeholder="全部设备状态"
        :options="deviceStatusOption"
        v-model="params.deviceStatus"
        allowClear
      ></a-select>
    </div>
    <div style="height: 50vh">
      <PaginationTable
        ref="tableRef"
        row-key="pkId"
        :api="api"
        :columns="columns"
        :scroll="{ x: false, y: 'max-content' }"
        :paginationConfig="{
          size: 'small',
          showQuickJumper: false,
        }"
      />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import AsyncSelect from '@/components/async-select';
import PaginationTable from '@/components/pagination-table';
import { nowSize } from '@/utils/common';
import moment from 'moment';
import { LayoutSelect } from '@/views/screen/layout';
import { getEdgeList } from '@/services/screen/jiangsu/edge-protection';
import {
  deviceStatusEnum,
  onOffEnum,
} from '@/views/safety-device/edge-protection/enum';
import { getDeviceLists } from '@/services/screen/jiangsu/vehicle';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';

@Component({
  components: { EmptyContent, AsyncSelect, PaginationTable, LayoutSelect },
})
export default class DataListModal extends Vue {
  @Prop({ required: true }) projectId;

  deviceGroup = deviceTypeEnum[pageTypeEnum.edgeProtection].deviceGroup;
  params = {
    deviceId: undefined,
    deviceStatus: undefined,
    defenseStatus: undefined,
  };

  get deviceStatusOption() {
    return Object.entries(deviceStatusEnum).map(([value, label]) => ({
      label,
      value,
    }));
  }

  get onOffOption() {
    return Object.entries(onOffEnum).map(([value, label]) => ({
      label,
      value,
    }));
  }

  deviceList = [];
  get deviceOption() {
    return this.deviceList.map(item => ({
      label: item.deviceName,
      value: item.pkId,
    }));
  }

  async getDeviceList() {
    this.deviceList = await getDeviceLists({
      deviceGroup: this.deviceGroup.toLocaleUpperCase(),
      idxProjectId: this.projectId,
    });
  }
  async api({ current, size }) {
    const res = await getEdgeList({
      current,
      size,
      idxProjectId: this.projectId,
      ...this.params,
    });
    return res;
  }
  columns = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      width: nowSize(200),
    },
    {
      title: '设备码',
      dataIndex: 'deviceCode',
      width: nowSize(200),
    },
    {
      title: '设备状态',
      dataIndex: 'deviceStatus',
      width: nowSize(100),
      customRender: text => deviceStatusEnum[text] ?? '-',
    },
    {
      title: '布防状态',
      width: nowSize(100),
      dataIndex: 'defenseStatus',
      customRender: text => onOffEnum[text] ?? '-',
    },
    {
      title: '电池电量',
      dataIndex: 'eleNumber',
      width: nowSize(100),
    },
    {
      title: '上报时间',
      dataIndex: 'ts',
      width: nowSize(200),
      customRender: text => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  selectChange() {
    this.$refs.tableRef.search();
  }

  created() {
    this.getDeviceList();
  }
}
</script>

<style lang="less" module></style>
