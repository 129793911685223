import request from '../../request';

const serviceName = '/smart-site/jiangsu/screen/other/pn';

/**
 * 临边防护设备列表
 * @param {object} body
 * @param {{size: number, current: number}} body.query
 * @param {string} body.idxProjectId 项目ID
 * @param {string} body.deviceId 设备ID
 * @param {string} body.defenseStatus 布防状态
 * @param {string} body.deviceStatus 设备状态
 * @returns
 */
export function getEdgeList(body) {
  return request(`${serviceName}/getPageRecord`, {
    method: 'GET',
    body,
  });
}

/**
 * 临边防护设备状态图表
 * @param {string} projectId 项目ID
 * @returns
 */
export function getEdgeDeviceStatus(projectId) {
  return request(`${serviceName}/getStateCount/${projectId}`, {
    method: 'GET',
  });
}

/**
 * 临边防护设备报警
 * @param {object} body 项目ID
 * @param {string} body.days 设备状态
 * @param {string} body.projectId 设备状态
 * @param {string} body.deviceId 设备状态
 * @returns
 */
export function getEdgeAlarmTypeStatics(body) {
  return request(`${serviceName}/statistics`, {
    method: 'POST',
    body,
  });
}

/**
 * 临边防护报警趋势
 * @param {object} body 项目ID
 * @param {string} body.days 设备状态
 * @param {string} body.projectId 项目ID
 * @param {string} body.deviceId 设备ID
 * @returns
 */
export function getEdgeAlarmTypeTrend(body) {
  return request(`${serviceName}/alarm/stat/trend/count`, {
    method: 'GET',
    body,
  });
}
