<template>
  <div class="grid grid-cols-2 h-full" :class="$style.dataWrap">
    <div
      class="flex items-center justify-center"
      v-for="(item, index) in list"
      :key="index"
    >
      <div
        class="flex flex-col items-center justify-center"
        :class="$style.item"
      >
        <span :class="$style.textShadow">{{ item.value }}</span>
        <div :class="$style.icon"></div>
        <span :class="$style.name">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class EdgeProtectionDataView extends Vue {
  get list() {
    return [
      {
        name: '倾角数据',
        value: '2',
        unit: '次',
      },
      {
        name: '沉降数据',
        value: '2',
        unit: '次',
      },
      {
        name: '位移数据',
        value: '2',
        unit: '次',
      },
      {
        name: '温度数据',
        value: '2',
        unit: '次',
      },
    ];
  }
}
</script>

<style lang="less" module>
.icon {
  background-image: url('~@/assets/images/theme/labor-icon.png');
  background-size: cover;
  width: 0.58rem;
  height: 0.14rem;
}
.textShadow {
  text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
  color: #fff;
  font-size: 0.24rem;
}
.item {
  width: 0.97rem;
  height: 0.88rem;
  background: linear-gradient(
    270deg,
    rgba(76, 175, 255, 0) 0%,
    rgba(76, 175, 255, 0.1) 52%,
    rgba(76, 175, 255, 0) 100%
  );
  .name {
    color: #d8e1f3;
    font-size: 0.12rem;
    padding-top: 0.1rem;
  }
}
.dataWrap {
  padding: 0.3rem 0.2rem;
}
</style>
